import steveImg from './assets/steve.jpg';
import mattiaImg from './assets/mattia.jpg';
import davidImg from './assets/david.jpg';
import { MetaTags } from './components/MetaTags';
import { WhatWeDo } from './components/WhatWeDo';
import { Header } from './components/Header';
import { Customers } from './components/Customers';
import { Employees } from './components/Employees';
import styled from 'styled-components';
import { Fragment } from 'react';
import GlobalStyle from './GlobalStyles';
import Logo from './assets/logo.svg';
import FloatingBanner from './components/FloatingBanner';
const Border = styled.hr`
    border: #344054 solid 2px;
`;

const LogoContainer = styled.img`
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 18.75rem;
  max-width: 80%;
`;

const AppStyle = styled.div`
  text-align: center;
  font-size: 1.375rem;
  font-family: 'Roboto', sans-serif;
  color: #FFF;
  background: #001318;
  margin: 0;
  overflow-x: hidden;
  padding: 0 0 10rem;
`;

const employees = [{
  name: 'Steve Widinghoff',
  experienceLevel: 'Worked professionally since 2008',
  mainFocus: 'Scalable microservices, Backend, Architecture',
  techStack: 'Java, Kotlin, GCP and much much more',
  linkedinUrl: 'steve-widinghoff',
  image: steveImg
},
{
  name: 'Mattia Picchio',
  experienceLevel: 'Worked professionally since 2009',
  mainFocus: 'Frontend / fullstack work including apps',
  techStack: 'React, Node, Flutter and much much more',
  linkedinUrl: 'mattiapicchio',
  image: mattiaImg
},
{
  name: 'David Irén',
  experienceLevel: 'UX / UI designer and Frontend developer',
  mainFocus: 'UX / UI',
  techStack: 'React, HTML, CSS, nodeJS',
  linkedinUrl: 'david-irén-735b21226',
  image: davidImg
}
];

function App() {
  return <Fragment>
    <GlobalStyle />
    <AppStyle>
      <LogoContainer src={Logo}/>
      <MetaTags />
      <Header/>
      <Border />
      <WhatWeDo/>
      <Border />
      <Customers/>
      <Border />
      <Employees employees={employees}/>
      <FloatingBanner/>
    </AppStyle>
  </Fragment>;
}

export default App;

